.add-edit-product-form {
    .ui.dropdown {
        margin-bottom: 20px;
    }
    &__active {
        margin-top: 20px;
    }
    .ui.primary.button {
        margin-top: 30px;
    }
    .ui.image {
        margin-top: 10px;
    }
}