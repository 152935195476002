.list-products-cart {
    &__product {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 0;

        > div {
            width: 180px;

            .ui.image {
                margin-right: 20px;
            }
        }
    }

    .ui.button {
        margin-top: 20px;
    }
}