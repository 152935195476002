.list-products-client {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &__product {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        margin: 10px 0;

        > div {
            display: flex;
            align-items: center;
        }

        .ui.image {
            margin-right: 20px;
        }
    }
}