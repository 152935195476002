.list-categories-client {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &__category {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #e6e6e6;
        margin: 10px 0;
        border-radius: 5px;

        .ui.image {
            margin-right: 10px;
        }
    }
}