.add-edit-user-form {
    &__active,
    &__staff {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ui.primary.button {
        margin-top: 30px;
    }
}