.payment-product-list {
    &__product {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;

        .ui.image {
            margin-right: 30px !important;
        }
    }
}