.select-table {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2a2a72;
    background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
    padding: 0 20px;

    &__content {
        background-color: #fff;
        padding: 40px 30px;
        border-radius: 20px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > h1 {
            margin-bottom: 10px;
            font-size: 25px;
        }

        > h2 {
            margin: 0;
            font-weight: normal;
            font-size: 18px;
            // text-align: center;
        }

        .ui.form {
            width: 100%;
            margin-top: 30px;
        }

        &-error {
            color: #ff0000;
            margin-top: 10px;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
}