.tables-list-admin {
    display: flex;
    flex-wrap: wrap;

    &__reload {
        margin: 0;
        position: absolute;
        top: 23px;
        right: 110px;
        z-index: 1;
    }

    &__reload-toggle {
        position: absolute;
        top: 30px;
        right: 170px;
        display: flex;
        align-items: center;
    }
}